import markdownit from 'markdown-it';
import axios from 'axios';
import { useEffect, useState } from 'react';

import './markdown-render.scss';
import { useParams } from 'react-router-dom';

type MarkdownRenderProps = {
  name: string;
};

const MarkdownRender: React.FC<MarkdownRenderProps> = ({ name }) => {
  const { locale } = useParams();
  const URL = `https://api.pearpeer.com/${name}/${locale ?? 'en'}`;

  const [data, setData] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const md = markdownit();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(URL);
        setData(response?.data?.value ?? 'Empty');
        setLoading(false);
      } catch (error) {
        setError('fetching data failed');
        setLoading(false);
      }
    };

    fetchData();
  }, [URL]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container">
      <div
        className="markdown-doc"
        dangerouslySetInnerHTML={{ __html: md.render(data) }}
      />
    </div>
  );
};

export default MarkdownRender;
