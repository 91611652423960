import './Home.scss';

function HomePage() {
  return (
    <>
      <div className="page-home">
        <div className="container">
          <div className="brief-intro">
            <div className="tagline">
              Master IELTS Speaking & Writing with{' '}
              <span className="brand-color">PearPeer</span>
            </div>
            <div className="intro">
              Prepare for your IELTS exam like never before with PearPeer, your
              smart companion for both writing and speaking practices. Dive into
              a seamless learning experience designed to boost your confidence
              and achieve your highest score.,
            </div>
          </div>
          <div className="download-badge">
            <a href="https://apps.apple.com/app/pearpeer-ielts/id6504406254">
              <img
                src="images/download/app-store-badge.png"
                alt="Download on App Store"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.pearpeer.ielts">
              <img
                src="images/download/google-play-badge.png"
                alt="Download on Google Play"
              />
            </a>
          </div>
        </div>

        <div id="features"></div>
        <div className="features">
          <div className="section container">
            <h2 className="section-title">Features</h2>
            <div className="feature-list">
              <div className="feature">
                <div className="feature-icon font-icon">&#xe800;</div>
                <div className="feature-content">
                  <h3>Interactive Speaking Practice</h3>
                  <div className="feature-description">
                    Engage in real-time conversations on a wide range of topics.
                    Perfect your pronunciation, fluency, and spontaneity.
                  </div>
                </div>
              </div>
              <div className="feature">
                <div className="feature-icon font-icon">&#xe801;</div>
                <div className="feature-content">
                  <h3>AI-Driven Analysis</h3>
                  <div className="feature-description">
                    Receive instant feedback aligned with IELTS scoring
                    criteria, for both your essays and oral conversations
                  </div>
                </div>
              </div>
              <div className="feature">
                <div className="feature-icon font-icon">&#xe802;</div>
                <div className="feature-content">
                  <h3>Writing Revision</h3>
                  <div className="feature-description">
                    Receive a revised essay improving grammar, vocabulary, and
                    structure for clarity and coherence while preserving your
                    original idea.
                  </div>
                </div>
              </div>
              <div className="feature">
                <div className="feature-icon font-icon">&#xe803;</div>
                <div className="feature-content">
                  <h3>Scoring</h3>
                  <div className="feature-description">
                    Obtain an estimated band score for both your essays and oral
                    conversations, based on the official IELTS scoring rubric.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="about"></div>
        <div className="container section about">
          <div className="section-title">About</div>
          <div className="section-content">
            PearPeer is a cutting-edge app designed to assist students preparing
            for the IELTS exam independently. Our app provides immediate
            feedback on essays and oral practice, utilizing OpenAI&apos;s
            advanced language models to mimic real test conditions and feedback.
          </div>
        </div>

        <div id="pricing"></div>
        <div className="container pricing section">
          <div className="section-title">Pricing</div>
          <div className="plans">
            <div className="plan">
              <h3>Buy As You Need</h3>
              <h4>Get Started for Free</h4>
              <div className="plan-description">
                Includes 3 writing practices and 3 speaking practices at{' '}
                <span className="brand-color">no cost</span>.
              </div>
              <h4>Flexible Add-Ons</h4>
              <div className="plan-description">
                Purchase additional writing or speaking practices for just{' '}
                <span className="brand-color">$1</span> each.
              </div>
            </div>
            <div className="plan">
              <h3>Subscription</h3>
              <ul>
                <li>Unlimited writing practices</li>
                <li>Unlimited speaking practices</li>
                <li>Cancel anytime</li>
              </ul>
              <div className="plan-price">
                <span className="price">$8.90</span>/month{' '}
                <span className="or">OR</span>
                <span className="price">$89.00</span>/year
              </div>
            </div>
          </div>
        </div>

        <div id="download"></div>
        <div className="container section download">
          <div className="section-title">Download</div>
          <div className="section-content">
            Ready to take your IELTS preparation to the next level? Download
            PearPeer now and start practicing today. Achieve the score you need
            with PearPeer by your side!
          </div>
          <div className="download-badge">
            <a href="https://apps.apple.com/app/pearpeer-ielts/id6504406254">
              <img
                src="images/download/app-store-badge.png"
                alt="Download on App Store"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.pearpeer.ielts">
              <img
                src="images/download/google-play-badge.png"
                alt="Download on Google Play"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
