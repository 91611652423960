import React from 'react';
import ReactDOM from 'react-dom/client';
import HomePage from './pages/home/Home';
import MarkdownRender from './markdown-render/markdown-render';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import './index.scss';
import DeleteAccount from './pages/qa/delete-account';
import Layout from './layout/Layout';
import CareersPage from './pages/careers/Careers';
import WhereToFindExerciseId from './pages/where_to_find_exercise_id/WhereToFindExerciseId';

const MainLayout = () => (
  <Layout>
    <Outlet />
  </Layout>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: 'careers',
        element: <CareersPage />,
      },
    ],
  },
  {
    path: '/help/where-to-find-exercise-id',
    element: <WhereToFindExerciseId />,
  },
  {
    path: '/qa/delete-account',
    element: <DeleteAccount />,
  },
  {
    path: '/privacy-policy/:locale',
    element: <MarkdownRender name="privacy-policy" />,
  },
  {
    path: '/terms-of-service/:locale',
    element: <MarkdownRender name="terms-of-service" />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
