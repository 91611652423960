import React, { useState } from 'react';
import './Layout.scss';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => {
    setIsOpened(!isOpened);
  };

  return (
    <>
      <div className="header-box"></div>
      <div className="header header-box">
        <div className="container">
          <a className="logo" href="/">
            <img src="images/logo.png" alt="PearPeer Logo" />
            <span className="brand-name">PearPeer</span>
          </a>
          <div className="nav">
            <button className="nav-toggle" onClick={handleClick}>
              <span className="font-icon">&#xe805;</span>
            </button>
            <div className={['nav-menu', isOpened ? 'block' : ''].join(' ')}>
              <a className="nav-button" href="/#features">
                Features
              </a>
              <a className="nav-button" href="/#pricing">
                Pricing
              </a>
              <a className="nav-button" href="/#about" data-l10n="navAbout">
                About
              </a>
              <a className="nav-button" href="/#download">
                Download
              </a>
              <a className="nav-button" href="/careers">
                Careers
              </a>
            </div>
            {isOpened && (
              <div className="nav-backdrop" onClick={handleClick}></div>
            )}
          </div>
        </div>
      </div>
      <main>{children}</main>
      <div className="footer">
        <div className="container">
          <a href="mailto:info@pearpeer.ai">info@pearpeer.ai</a>
          <div className="divider">|</div>
          <span>&copy; 2024 PearPeer</span>
        </div>
      </div>
    </>
  );
};

export default Layout;
