import React from 'react';
import './Careers.scss';
type CareersProps = {};

const CareersPage: React.FC<CareersProps> = () => {
  return (
    <div className="page-careers">
      <div className="container">
        <h1>Open Positions</h1>
        <ul className="position-list">
          <li>
            <a href="#product-manager">Product Manager</a>
          </li>
          <li>
            <a href="#ui-designer">UI/UX Designer</a>
          </li>
        </ul>

        <div className="career-position" id="product-manager">
          <h2>Product Manager</h2>
          <h3>Job Description</h3>
          <p>
            We seek a dynamic, cross-functional Product Manager to lead the
            development and execution of our product strategies. This role
            involves close collaboration with marketing, design, and development
            teams to ensure our products meet market needs and drive growth.
          </p>
          <h3>Key Responsibilities</h3>
          <ul>
            <li>
              Define and execute the product vision, strategy, and roadmap.
            </li>
            <li>
              Conduct market research and analyze user feedback to identify
              opportunities and improvements.
            </li>
            <li>
              Collaborate with engineering, design, marketing, and sales teams
              to deliver high-quality products on time.
            </li>
            <li>
              Manage the product development lifecycle, from concept to launch.
            </li>
            <li>
              Prioritize features, manage the product backlog, and make
              data-driven decisions.
            </li>
            <li>
              Oversee and contribute to social media and digital marketing
              strategies to align with product goals.
            </li>
            <li>
              Monitor product performance and drive continuous improvement.
            </li>
            <li>Communicate product updates and progress to stakeholders.</li>
          </ul>
          <h3>Qualifications</h3>
          <ul>
            <li>
              Proven experience as a Product Manager or in a similar
              cross-functional role.
            </li>
            <li>
              Strong understanding of product management, including agile
              methodologies.
            </li>
            <li>
              Experience with online marketing strategies and tools is a plus.
            </li>
            <li>
              Excellent communication, collaboration, and leadership skills.
            </li>
            <li>
              Ability to work independently and make data-driven decisions.
            </li>
            <li>Fluent in English, both written and spoken.</li>
          </ul>
          <h3>How to Apply</h3>
          <p>
            Please send your resume, portfolio, and a cover letter to{' '}
            <a href="mailto:hr@pearpeer.ai">hr@pearpeer.ai</a> with the subject
            line <i>"Product Manager Application - [Your Name]"</i>.
          </p>
        </div>
        <hr />
        <div className="career-position" id="ui-designer">
          <h2>UI Designer</h2>
          <h3>Job Description</h3>
          <p>
            We are seeking a talented UI/UX Designer to join our team. As a
            UI/UX Designer at PearPeer, you will be responsible for designing
            intuitive and visually appealing user interfaces for our language
            learning apps, and the ability to enhance user experience through
            innovative design solutions. You will work closely with our product
            and development teams to create seamless user experiences.
          </p>
          <h3>Key Responsibilities</h3>
          <ul>
            <li>
              Design and enhance user interfaces for mobile apps and our
              websites.
            </li>
            <li>
              Design social media posts, banners, and other marketing materials.
            </li>
            <li>
              Create wireframes, mockups, and prototypes to effectively
              communicate design ideas.
            </li>
            <li>
              Collaborate with product managers, developers, and other
              stakeholders to ensure designs meet user needs. and business
              goals.
            </li>
            <li>
              Conduct user research and usability testing to gather feedback and
              improve designs.
            </li>
            <li>Stay up-to-date with design trends and best practices.</li>
          </ul>
          <h3>Qualifications</h3>
          <ul>
            <li>
              Bachelor's degree background or relevant work experience,
              certification, or courses.
            </li>
            <li>
              2-5 years of experience in UI/UX design, preferably in the tech or
              education sectors.
            </li>
            <li>Strong understanding of User-Centered Design and usability.</li>
            <li>
              Strong portfolio showcasing a range of UI/UX designs and
              problem-solving skills.
            </li>
            <li>
              Proficiency in design tools such as Figma, Adobe XD, Sketch, or
              similar.
            </li>
            <li>
              Experience with Agile methodologies and project management tools.
            </li>
            <li>Excellent communication and teamwork skills.</li>
            <li>Proficiency in written and verbal English.</li>
          </ul>
          <h3>How to Apply</h3>
          <p>
            Please send your resume, portfolio, and a cover letter to{' '}
            <a href="mailto:hr@pearpeer.ai">hr@pearpeer.ai</a> with the subject
            line <i>"UI/UX Designer Application - [Your Name]"</i>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CareersPage;
