import axios from 'axios';
import { useEffect, useState } from 'react';
import './WhereToFindExerciseId.scss';
import React from 'react';

const buketUrl = 'https://s3-bucket.ms.hanlogy.cloud';

function formatString(input: string): string {
  const upperCaseInput = input.toUpperCase();

  const formattedString =
    upperCaseInput.slice(0, 4) + '-' + upperCaseInput.slice(4);

  return formattedString;
}

function convertNewlinesToBr(text: string) {
  return text.split('\n').map((str, index) => (
    <React.Fragment key={index}>
      {str}
      <br />
    </React.Fragment>
  ));
}

const input = 'vjpe6716';
const formatted = formatString(input);
console.log(formatted); // Outputs: VJPE-6716

function groupByType(items: any[]): any[] {
  const grouped: {
    [key: string]: { type: string; items: {}[] };
  } = {};

  items.forEach((item) => {
    if (!grouped[item.type]) {
      grouped[item.type] = { type: item.type, items: [] };
    }
    grouped[item.type].items.push(item);
  });

  return Object.values(grouped);
}

const WhereToFindExerciseId = () => {
  const URL = `https://api.pearpeer.com/exercises/question-bank`;

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(URL);
        const items = groupByType(response?.data?.items ?? []);
        setData(items);
        setLoading(false);
      } catch (error) {
        setError('fetching data failed');
        setLoading(false);
      }
    };

    fetchData();
  }, [URL]);

  return (
    <>
      <div className="container where-to-find-exercise-id-page">
        {loading && <div className="loading">Loading...</div>}
        {error && <div className="error">{error}</div>}
        <a href="/" className="back-to-homepage">
          &lt; Back to PearPeer homepage
        </a>
        <h1>Where to Find Exercise IDs?</h1>
        <h2>Exercise IDs Shared by Other Users:</h2>
        <p>
          We have a feature that allows users to copy exercise IDs and share
          them with others. You can find the{' '}
          <span className="text-brand-color">&quot;Copy Exercise ID&quot;</span>{' '}
          button on the exercise list page for each exercise.
        </p>
        <h2>You can also use these exercise example IDs:</h2>

        <div className="scroll-to-links">
          <a href="#speaking">Speaking</a> | <a href="#writing1">Writing1</a> |{' '}
          <a href="#writing2">Writing2</a>
        </div>

        <div>
          {data.map((group) => (
            <div
              id={group.type}
              className="example-section"
              key={`exercise-${group.type}`}
            >
              <h3>{group.type}</h3>
              {group.items.map((item: any, index: number) => (
                <div
                  className="exercise-item"
                  key={`exercise-${item.short_id}`}
                >
                  <div className="exercise-id">
                    ID: <span>{formatString(item.short_id)}</span>
                  </div>
                  {item.type === 'speaking' && (
                    <div>
                      <h4>part1:</h4>
                      <div className="speaking-task">{item.part1}</div>
                      <h4>part2:</h4>
                      <div className="speaking-task">
                        {convertNewlinesToBr(item.part2)}
                      </div>
                    </div>
                  )}
                  {(item.type === 'writing1' || item.type === 'writing2') && (
                    <div>
                      <h4>task:</h4>
                      <div className="writing-task">
                        {convertNewlinesToBr(item.task)}
                      </div>
                      {item.type === 'writing1' && (
                        <div className="writing-image">
                          <img
                            src={`${buketUrl}/pearpeer-app/writing1/${item.image}`}
                            alt="writing task"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
              <hr />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WhereToFindExerciseId;
