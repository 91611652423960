import './delete-account.scss';

function DeleteAccount() {
  return (
    <main className='delete-account'>
      <img src="/images/logo-with-text-bg.png" alt="logo" />
      <p>
        You can delete you account by going to your account settings and
        clicking the "Delete Account" button.
      </p>

      <p>
        You can contact us at pearpeer@hanlogy.com if you have any questions.
      </p>
    </main>
  );
}

export default DeleteAccount;
